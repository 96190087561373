<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <h4>Insert data for {{ language.name }}</h4>
      {{/*  
        Fields: title, reward, instructions
        */}}
      <form-text-input
        label="Research Title"
        name="Research Title"
        rules="required"
        v-model="localization.title"
      />
      <form-text-input
        label="Research Reward"
        name="Research Reward"
        rules="required"
        v-model="localization.reward"
      />
      <form-text-input
        label="Research Instructions"
        name="Research
      Instructions"
        rules="required"
        v-model="localization.instructions"
      />
      <b-container fluid class="d-flex flex-row-reverse p-0">
        <b-button type="submit" variant="primary"> Next </b-button>
        <b-button @click="$emit('on-prev')" class="mr-1" variant="secondary">
          Prev
        </b-button>
      </b-container>
    </b-form>
  </ValidationObserver>
</template>

<script>
/**
 * This component is used in the ResearchModalStepped component to display the inputs for each language. It is not used on its own.
 * The component handles the form display and validation.
 */
import FormTextInput from "@/components/FormTextInput.vue";
import { ValidationObserver } from "vee-validate";
import { ResearchLocalization } from "@/utils/Research";
import { BForm, BButton, BContainer } from "bootstrap-vue";
export default {
  components: {
    FormTextInput,
    ValidationObserver,
    BForm,
    BButton,
    BContainer,
  },
  props: {
    language: {
      type: Object,
      required: true,
    },
    localization: {
      type: ResearchLocalization,
      required: true,
    },
  },
  emits: ["on-submit", "on-prev"],
  methods: {
    onSubmit() {
      this.$emit("on-submit");
    },
  },
};
</script>
