import { Research, ResearchLocalization } from '@/utils/Research'

// api call wrappers for the "Researches" collection
export async function getResearchesOfUser(userId, page = 1, pageSize = 100) {
  // const whereClause = `ownerId = '${userId}'`;
  const queryBuilder = Backendless.DataQueryBuilder.create()
  queryBuilder
    .setPageSize(pageSize)
    .setOffset(page - 1)
    .setRelationsDepth(2)
    .setSortBy(['created DESC'])
  const query = Backendless.Data.of('Researches')
  const result = await query
    .find(queryBuilder)
    .then(results => results
      .filter(element => element.dn != 'SPAM ')
      .map(element => ({
        id: element.objectId, // get("objectId"),
        Age: element.Age,
        Description: element.DescriptionEN,
        drName: element.drName,
        GameId: element.GameId,
        gameLevel: element.gameLevel,
        GameRoute: element.GameRoute,
        Options: element.Options,
        isGame: element.isGame,
        ResearchType: element.ResearchType,
        ownerId: element.ownerId,
        created: element.created,
        updated: element.updated,
        autoCorrect: element.autoCorrect,
        enabled: element.enabled,
        suspended: element.suspended,
        isRandom: element.isRandom,
        Gender: element.Gender,
        Vision: element.Vision,
        Spoken: element.Spoken,
        Handedness: element.Handedness,
        localizations: element.localizations,
        stats: element.stats,
        // mark: element.marked == 1 ? true : false,
      })))
    .catch(e => {
      console.log(e)
      return []
    })

  return result
}

/**
 * @description Converts a table row to a ResearchData object
 * @param {*} tableRow Row obtained from the getResearchesOfUser API call
 */
export function toResearchData(tableRow) {
  return new Research({
    minAge: tableRow.Age,
    description: tableRow.Description,
    researcherName: tableRow.drName,
    researchType: tableRow.ResearchType,
    autoCorrect: tableRow.autoCorrect,
    vision: tableRow.Vision,
    gender: tableRow.Gender,
    language: tableRow.Spoken,
    handedness: tableRow.Handedness,
    randomize: tableRow.isRandom,
    stats: tableRow.stats,
    localizations: tableRow.localizations.map(element => new ResearchLocalization({
      title: element.title,
      reward: element.reward,
      instructions: element.instructions,
      lang: element.lang,
      objectId: element.objectId,
    })),
    game: {
      routName: tableRow.GameRoute,
      objectId: tableRow.GameId,
      level: parseInt(tableRow.gameLevel),
    },
  })
}

export function saveResearchToDB(researchData, objectId = null) {
  const research = {
    Age: parseInt(researchData.minAge),
    DescriptionEN: researchData.description,
    drName: researchData.researcherName,
    isGame: researchData.researchType == 'game',
    GameId: researchData.game.objectId,
    gameLevel:
            researchData.game.level != null && researchData.researchType == 'game'
              ? parseInt(researchData.game.level)
              : 0,
    Options:
            researchData.game.level != null && researchData.researchType == 'game'
              ? parseInt(researchData.game.level)
              : 0,
    GameRoute:
            researchData.game != null && researchData.researchType == 'game'
              ? researchData.game.routName
              : '',
    ResearchType: researchData.researchType,
    enabled: false,
    isRandom:
            researchData.researchType == 'perceptual'
              ? researchData.randomize
              : false,
    autoCorrect:
            researchData.autoCorrect != null && researchData.researchType != 'game'
              ? researchData.autoCorrect
              : false,
    Gender: researchData.gender,
    Vision: researchData.vision,
    Spoken: researchData.language,
    Handedness: researchData.handedness,
    ownerId: this.userId,
    localizations: researchData.localizations,
  }

  if (objectId) {
    research.objectId = objectId
  }

  return Backendless.Data.of('Researches').deepSave(research)
}
