<template>
  <b-row>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group class="mb-0">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-secondary"
          @click="showModalAddResearch()"
        >
          ADD Research
        </b-button>
      </b-form-group>
    </b-col>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group class="mb-0">
        <label
          class="d-inline-block text-sm-left mr-50"
          for="perPageSelect"
        >Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <b-form-group
        label="Sort"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      class="my-1"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <!-- Column: Id -->
        <template #cell(enabled)="data">
          <b-badge
            v-if="data.value == true"
            variant="success"
          >
            Enabled
          </b-badge>
          <b-badge
            v-else
            variant="danger"
          >
            Not Enabled
          </b-badge>
        </template>

        <template #cell(suspended)="data">
          <b-badge
            v-if="data.value == false"
            variant="success"
          >
            Enabled
          </b-badge>
          <b-badge
            v-else
            variant="danger"
          >
            Not Enabled
          </b-badge>
        </template>

        <template #cell(isRandom)="data">
          <b-badge
            v-if="data.value == true"
            variant="success"
          >
            TRUE
          </b-badge>
          <b-badge
            v-else
            variant="danger"
          >
            FALSE
          </b-badge>
        </template>

        <template #cell(action)="data">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="showModaldetails(data)">
                <feather-icon
                  icon="LayersIcon"
                  class="mr-50"
                />
                <span>Questions</span>
                <!-- modal data -->
              </b-dropdown-item>
              <b-dropdown-item @click="showModalAnswers(data)">
                <feather-icon
                  icon="LayersIcon"
                  class="mr-50"
                />
                <span>Answers</span>
                <!-- modal data -->
              </b-dropdown-item>
              <b-dropdown-item @click="showModalUpdateResearch(data)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
                <!-- modal data -->
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.enabled == true"
                @click="updateResearchState(data, false)"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Disable </span>
                <!-- modal data -->
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="updateResearchState(data, true)"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Enable</span>
                <!-- modal data -->
              </b-dropdown-item>
              <b-dropdown-item @click="showComfirmDeleteUser(data)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>
    </b-col>

    <research-modal-stepped
      ref="my-modal-add-research"
      :research="researchData"
      :form-options="formOptions"
      @on-submit="handleSubmitAddResearch"
      @reset-modal="resetModal"
    />

    <research-modal-stepped
      ref="my-modal-update-research"
      :research="researchData"
      :form-options="formOptions"
      @on-submit="handleSubmitUpdateResearch"
      @reset-modal="resetModal"
    />

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@validations'
import Research from '@/utils/Research'
import { getResearchesOfUser, toResearchData } from '@/api/research'
import ResearchModalStepped from './Modals/ResearchModalStepped.vue'

export default {
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    ResearchModalStepped,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      researchData: new Research(),
      required,
      email,
      formOptions: {
        gameTypes: [],
        researchTypes: [],

        autoCorrect: [
          { txt: 'True', val: true },
          { txt: 'False', val: false },
        ],
        stats: [
          { txt: 'True', val: true },
          { txt: 'False', val: false },
        ],
        gender: [
          { txt: 'All', val: 0 },
          { txt: 'Male', val: 1 },
          { txt: 'Female', val: 2 },
        ],
        handedness: [
          { txt: 'Both', val: 0 },
          { txt: 'Left', val: 1 },
          { txt: 'Right', val: 2 },
        ],
        vision: [
          { txt: 'True', val: true },
          { txt: 'False', val: false },
        ],
        language: [
          { txt: 'EN', val: 'en' },
          { txt: 'AR', val: 'ar' },
          { txt: 'FR', val: 'fr' },
        ],
      },

      username: '',
      email: '',
      password: '',
      user: '',
      cardsParseObjects: [],
      currentCards: [],
      validationState: {
        name: null,
        title: {
          en: null,
          ar: null,
          fr: null,
        },
        age: null,
        researcherName: null,
        instructions: {
          en: null,
          ar: null,
          fr: null,
        },
        earns: {
          en: null,
          ar: null,
          fr: null,
        },
        description: {
          en: null,
          ar: null,
          fr: null,
        },
        email: null,
        password: null,
      },
      perPage: 50,
      pageOptions: [50, 75, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        // { key: "id", label: "Id", sortable: true },
        { key: 'localizations[0].title', label: 'Title', sortable: true },
        { key: 'ResearchType', label: 'Research Type', sortable: true },
        { key: 'drName', label: 'DR Name' },
        { key: 'Description', label: 'description', sortable: true },
        { key: 'Age', label: 'Age', sortable: true },
        { key: 'localizations[0].reward', label: 'Reward', sortable: true },
        // { key: "GameId", label: "Game Id", sortable: true },
        { key: 'gameLevel', label: 'Game Level', sortable: true },
        // { key: "GameRoute", label: "Game Route In App", sortable: true },
        {
          key: 'localizations[0].instructions',
          label: 'Instructions',
          sortable: true,
        },
        { key: 'isGame', label: 'Is Game', sortable: true },
        { key: 'autoCorrect', label: 'Auto Correct', sortable: true },
        { key: 'isRandom', label: 'is Random', sortable: true },
        { key: 'stats', label: 'Stats And Confetti', sortable: true },
        // { key: "ownerId", label: "Owner Id", sortable: true },
        { key: 'suspended', label: 'suspended by Admin', sortable: true },
        { key: 'enabled', label: 'Enabled by User', sortable: true },
        // { key: "created", label: "Created At", sortable: true },
        // { key: "updated", label: "Updated At", sortable: true },

        'action',
      ],
      items: [],

      cards: [],
      // formOptions.gameTypes: [],
      selectedResearch: null,

      selectedAvailableGamesTypes: [],
      selectedAvailableGamesTypesOption: null,
      selecctedResearchesGender: null,
      selectedResearchesHandedness: null,
      selectedResearchesVision: null,
      selectedResearchesSpoken: null,
      selectedPerceptualRandom: { txt: 'True', val: true },
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
      userId: Backendless.LocalCache.get('current-user-id'),
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    // TODO: split into two objects: selectedResearchData and currentResearchData. Both should have exactly the same fields, selectedResearchData is where we cache the selections in the modal. Moreover, "state" variables should be moved into their own ValidationState object. Finally, this should not be a computed property, as we need to be able to set the values from the outside. Thus, all the individual fields should be either be computed properties OR re-written as elements of the researchData object.
  },
  mounted() {
    // Set the initial number of items
    this.ResearchesTypes()
    this.availableGames()
    this.getRows()
  },
  methods: {
    portKeydown(e) {
      if (/^\+$/.test(e.key)) {
        e.preventDefault()
      }
    },
    async saveResearchToDB(objectId = null) {
      const that = this
      const research = {
        Age: parseInt(this.researchData.minAge),
        DescriptionEN: this.researchData.description,
        drName: this.researchData.researcherName,
        isGame: this.researchData.researchType == 'game',
        GameId: this.researchData.game.objectId,
        gameLevel:
            this.researchData.game.level != null
            && this.researchData.researchType == 'game'
              ? parseInt(this.researchData.game.level)
              : 0,
        Options:
            this.researchData.game.level != null
            && this.researchData.researchType == 'game'
              ? parseInt(this.researchData.game.level)
              : 0,
        GameRoute:
            this.researchData.game != null
            && this.researchData.researchType == 'game'
              ? this.researchData.game.routName
              : '',
        ResearchType: this.researchData.researchType,
        enabled: false,
        isRandom: this.researchData.randomize,
        autoCorrect:
            this.researchData.autoCorrect != null
            && this.researchData.researchType != 'game'
              ? this.researchData.autoCorrect
              : false,
        Gender: this.researchData.gender,
        Vision: this.researchData.vision,
        Spoken: this.researchData.language,
        Handedness: this.researchData.handedness,
        ownerId: this.userId,
        localizations: this.researchData.localizations,
        stats: this.researchData.stats,
      }

      if (objectId) {
        delete research.ownerId
        research.objectId = objectId
      }

      Backendless.Data.of('Researches')
        .deepSave(research)
        .then(async savedObject => {
          const uncategorized = 'C6E64507-FF1C-4AAB-879B-6540BA1BE17A'
          await Backendless.Data.of('ResearchCategories').addRelation(uncategorized, 'researches', [savedObject.objectId])
          this.$refs['my-modal-add-research'].hide()
          this.$refs['my-modal-update-research'].hide()
          that.getRows()
        })
        .catch(error => {
          console.log(`an error research has occurred ${error.message}`)
          that.$toast({
            component: ToastificationContent,
            props: {
              title: error.message,
              icon: 'BellIcon',
              // text: this.username,
              variant: 'danger',
            },
          })
        })
    },
    showModalUpdateResearch(research) {
      this.researchData = toResearchData(research.item)
      this.researchData.id = research.item.id
      this.$refs['my-modal-update-research'].show()
    },
    showModalAddResearch() {
      this.researchData = new Research()
      this.$refs['my-modal-add-research'].show()
    },

    resetModal() {
      console.log('resetmodel')
      this.titleEn = ''
      this.titleEnState = null
      this.titleAr = ''
      this.age = ''
      this.drName = ''
      this.instructionAr = ''
      this.instructionEn = ''
      this.EarnsEn = ''
      this.EarnsAr = ''
      this.descriptionEn = ''
      this.descriptionAr = ''
      this.selecctedResearchesGender = null
      this.selectedResearchesHandedness = null
      this.selectedResearchesVision = null
      this.selectedResearchesSpoken = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },

    handleSubmitAddResearch() {
      this.saveResearchToDB()
      // Hide the modal manually
    },
    handleSubmitUpdateResearch() {
      this.saveResearchToDB(this.researchData.id)
      // Hide the modal manually
    },
    showModaldetails(research) {
      // name: 'researches-questions',
      //     params: { id: data.value, type: data.item.ResearchType }

      this.$router.push({
        name: 'researches-questions',
        params: { id: research.item.id, type: research.item.ResearchType },
      })
    },

    showModalAnswers(research) {
      this.$router.push({
        name: 'researches-answers',
        params: { researchId: research.item.id },
      })
    },
    updateResearchState(research, status) {
      Backendless.Data.of('Researches')
        .save({ objectId: research.item.id, enabled: status })
        .then(savedObject => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Research updated Successfully',
              icon: 'BellIcon',
              text: research.item.TitleEN,
              variant: 'success',
            },
          })
          this.getRows()
        })
        .catch(function (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Research updated error ',
              icon: 'BellIcon',
              text: error,
              variant: 'danger',
            },
          })
          console.error('error:- ', error)
        })
    },

    showComfirmDeleteUser(research) {
      this.boxTwo = ''
      const that = this
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to delete the Study  ${research.item.TitleEN} .`,
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            Backendless.Data.of('Researches')
              .remove({ objectId: research.item.id })
              .then(timestamp => {
                // The object was deleted from the Parse Cloud.
                that.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Study Deleted Successfully',
                    icon: 'BellIcon',
                    text: research.item.TitleEN,
                    variant: 'success',
                  },
                })
                that.getRows()
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getRows() {
      // get users from server
      const that = this
      that.items = []
      that.totalRows = 0
      that.currentPage = 1
      getResearchesOfUser(that.userId).then(data => {
        that.items = data
        that.totalRows = data.length
      })
    },
    ResearchesTypes() {
      // get users from server
      const that = this
      that.formOptions.researchTypes = []
      const query = Backendless.Data.of('ResearchesTypes')
      query
        .find()
        .then(results => {
          // filter the results and map them to a new array of objects
          that.formOptions.researchTypes = results
            .filter(result => result.dn != 'SPAM ')
            .map(result => result.name)
        })
        .catch(e => {
          console.log(e)
        })
    },
    availableGames() {
      // AvailableGames
      // get AvailableGames from server
      const that = this
      // that.researchesTypes = [];
      that.formOptions.gameTypes = []
      const query = Backendless.Data.of('AvailableGames')
      query
        .find()
        .then(results => {
          that.formOptions.gameTypes = results
            .filter(result => result.dn != 'SPAM ')
            .map(result => ({
              name: result.GameNameEN,
              routName: result.routName,
              objectId: result.objectId,
              options: result.options,
            }))
        })
        .catch(e => {
          console.log(e)
        })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
