/**
 * @class Research
 * @classdesc class wrapper for research data, as stored in the database and quered in the GamesResearches page
 */
class Research {
  /**
     * @param {number} minAge - the minimum age of the participants
     * @param {number} maxAge - the maximum age of the participants
     * @param {LocalizedString} title - the title of the research
     * @param {LocalizedString} description - the description of the research
     * @param {string} researcherName - the name of the researcher
     * @param {string} researchType - the type of the research
     * @param {boolean} autoCorrect - whether the research has auto correct
     * @param {boolean} stats - whether the research show stats and confetti
     * @param {boolean} vision - whether the research requires normal vision
     * @param {number} gender - whether the research is restricted to specific genders
     * @param {number} handedness - whether the research is restricted to specific handedness
     * @param {string} language - the language of the research
     * @param {LocalizedString} earns - the amount of money the participant earns
     * @param {LocalizedString} instructions - the instructions of the research
     * @param {boolean} randomize - whether the research has randomization
     * @param {object} game - the game of the research
     * @param {string} game.routName - the route name of the game
     * @param {string} game.objectId - the object id of the game
     * @param {number} game.level - the level of the game
     *
     */

  constructor({
    minAge = 0,
    maxAge = 0,
    description = '',
    researcherName = '',
    researchType = 'game',
    autoCorrect = false,
    vision = false,
    gender = 0,
    handedness = 0,
    language = 'en',
    randomize = false,
    stats = true,
    game = {
      routName: '',
      objectId: '',
      level: 0,
    },
    localizations = [],
  } = {}) {
    this.minAge = minAge
    this.maxAge = maxAge
    this.description = description
    this.researcherName = researcherName
    this.researchType = researchType
    this.autoCorrect = autoCorrect
    this.vision = vision
    this.gender = gender
    this.handedness = handedness
    this.language = language
    this.randomize = randomize
    this.localizations = localizations
    this.stats = stats
    this.game = {
      ...game,
    }
  }
}

/**
 * @class ResearchLocalization
 * @classdesc wrapper for research data that differs in language
 *
 */
class ResearchLocalization {
  constructor({
    title = '',
    reward = '',
    instructions = '',
    lang = 'en',
    objectId = null,
  } = {}) {
    this.title = title
    this.reward = reward
    this.instructions = instructions
    this.lang = lang
    this.objectId = objectId
  }
}

export default Research
export { ResearchLocalization, Research }
