import Vue from 'vue'
import VueRouter from 'vue-router'
import {isUserLoggedIn} from '@/auth/utils'
import GameResearches from '@/views/pages/GameResearches'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'home',
            tag: 'new',
            component: () => import('@/views/pages/Home.vue'),
            meta: {
                pageTitle: 'Researches Analytics',
                redirectIfLoggedIn: true,
                breadcrumb: [
                    {
                        text: 'Analytics',
                        active: true,
                    },
                ],
                isAuth: true,
            },
        },
        {
            path: '/gameresearches',
            name: 'gameresearches',
            component: GameResearches,
            meta: {
                pageTitle: 'GameResearches',
                redirectIfLoggedIn: true,
                breadcrumb: [
                    {
                        text: 'Researches',
                        active: true,
                    },
                ],
                isAuth: true,
            },
        },
        {
            path: '/gameresearches/questions/:id',
            name: 'researches-questions',
            component: () => import('@/views/pages/Questions'),
            meta: {
                pageTitle: 'Researches Questions',
                // contentClass: 'ecommerce-application',
                breadcrumb: [
                    {
                        text: 'Researches',
                        active: false,
                        to: '/gameresearches',
                    },
                    {
                        text: 'Questions',
                        active: true,
                    },
                ],
                isAuth: true,
            },
        },
        {
            path: '/gameresearches/answers/:researchId',
            name: 'researches-answers',
            component: () => import('@/views/pages/Answers.vue'),
            meta: {
                pageTitle: 'Researches Answers',
                // contentClass: 'ecommerce-application',
                breadcrumb: [
                    {
                        text: 'Researches',
                        active: false,
                        to: '/gameresearches',
                    },
                    {
                        text: 'Research Participants',
                        active: true,
                    },
                ],
                isAuth: true,
            },
        },
        {
            path: '/gameresearches/research/:researchId/entry/:entryId/user/:userId',
            name: 'researches-answers-questions',
            component: () => import('@/views/pages/ResearchAnswersQuestions.vue'),
            meta: {
                pageTitle: 'Researches Questions user Answer',
                // contentClass: 'ecommerce-application',
                breadcrumb: [
                    {
                        text: 'Researches',
                        active: false,
                        to: '/gameresearches',
                    }, {
                        text: 'Research Participants',
                        active: false,
                        to: '/gameresearches/answers/:researchId',
                    },
                    {
                        text: 'Participant Answers',
                        active: true,
                    },
                ],
                isAuth: true,
            },
        }, {
            path: '/gameresearches/research/:researchId/entry/:entryId/user/:userId/Statistics',
            name: 'researches-answers-questions-Statistics',
            props: true,
            component: () => import('@/views/pages/Statistics.vue'),
            meta: {
                pageTitle: 'Participant Answers Statistics',
                breadcrumb: [
                    {
                        text: 'Researches',
                        active: false,
                        to: '/gameresearches',
                    },
                    {
                        text: 'Research Participants',
                        active: false,
                        to: '/gameresearches/answers/:researchId',
                    }, {
                        text: 'Participant Answers',
                        active: false,
                        to: '/gameresearches/research/:researchId/entry/:entryId/user/:userId',
                    },
                    {
                        text: 'Questions Answers Statistics',
                        active: true,
                    },
                ],
                isAuth: true,
            },
        }, {
            path: '/gameresearches/answers/:researchId/statistics',
            name: 'researches-answers-questions-Statistics',
            props: true,
            component: () => import('@/views/pages/Statistics.vue'),
            meta: {
                pageTitle: 'Research Answers Statistics',
                breadcrumb: [
                    {
                        text: 'Researches',
                        active: false,
                    },
                    {
                        text: 'Research Participants',
                        active: false,
                        to: '/gameresearches/answers/:researchId',
                    },
                    {
                        text: 'Questions Answers Statistics',
                        active: true,
                    },
                ],
                isAuth: true,
            },
        },
        {
            path: '/offers',
            name: 'offers',
            component: () => import('@/views/pages/Offers.vue'),
            meta: {
                pageTitle: 'Offers',
                breadcrumb: [
                    {
                        text: 'Offers',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/special-offers',
            name: 'special-offers',
            component: () => import('@/views/pages/SpecialOffers.vue'),
            meta: {
                pageTitle: 'special Offer',
                breadcrumb: [
                    {
                        text: 'special Offer',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
                layout: 'full',
                redirectIfLoggedIn: true,
                isAuth: false,
            },
        },
        {
            path: '/signup',
            name: 'signup',
            component: () => import('@/views/pages/Signup.vue'),
            meta: {
                layout: 'full',
                redirectIfLoggedIn: true,
                isAuth: false,
            },
        },
        {
            path: '/pages/account-setting',
            name: 'pages-account-setting',
            component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
            meta: {
                isAuth: true,
                pageTitle: 'Account Settings',
                breadcrumb: [
                    {
                        text: 'Pages',
                    },
                    {
                        text: 'Account Settings',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: () => import('@/views/pages/Privacy.vue'),
            meta: {
                layout: 'full',
                redirectIfLoggedIn: false,
                isAuth: false,
                isGuest: true,
            },
        },
        {
            path: '/policy',
            name: 'Policy',
            component: () => import('@/views/pages/Policy.vue'),
            meta: {
                layout: 'full',
                redirectIfLoggedIn: false,
                isAuth: false,
                isGuest: true,
            },
        },
        {
            path: '/verification/:id',
            name: 'verification',
            component: () => import('@/views/pages/Verificaiton.vue'),
            meta: {
                layout: 'full',
                redirectIfLoggedIn: true,
                isAuth: false,
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
                isAuth: false,
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        }, {
            path: '/categories',
            name: 'categories',
            tag: 'categories',
            component: () => import('@/views/pages/categories/Categories.vue'),
            meta: {
                pageTitle: 'Research Studies Categories',
                redirectIfLoggedIn: true,
                breadcrumb: [
                    {
                        text: 'Categories',
                        active: true,
                    },
                ],
                isAuth: true,
            },
        },
    ],
})

router.beforeEach(async (to, _, next) => {
    const isLoggedIn = isUserLoggedIn() != null
    if (typeof to.meta === 'function') {
        to.meta = to.meta(to)
    }
    if (to.meta.isGuest) {
        next()
    } else if (to.meta.isGuest == null) {
        if (!to.meta.isAuth && !isLoggedIn) {
            next()
        }
        if (to.meta.isAuth && !isLoggedIn) {
            next({name: 'login'})
        }
        if (!to.meta.isAuth && isLoggedIn) {
            next({name: 'home'})
        }
        if (to.meta.isAuth && isLoggedIn) {
            next()
        }
    }
})

router.afterEach(() => {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
