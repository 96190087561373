import Backendless from 'backendless'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
export const backendlessAppId = '1B87CFC9-DC13-690E-FFA2-F557A5EF0E00'
export const backendlessApiKey = '42EA9536-AFCF-44F9-8212-C1B9154B124F'
export const backendlessHost = 'https://api.backendless.com'
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  Backendless.serverURL = backendlessHost
  Backendless.initApp(backendlessAppId, backendlessApiKey)
  return Backendless.LocalCache.get('current-user-id')
}
