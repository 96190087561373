import Vue from 'vue'

import JsonExcel from 'vue-json-excel'
import { ModalPlugin, ProgressPlugin, ToastPlugin } from 'bootstrap-vue'

import VuePhoneNumberInput from 'vue-phone-number-input'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './views/components/global/global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/Twilio'
// import '@/libs/axios'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

Vue.component('vue-phone-number-input', VuePhoneNumberInput)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(ProgressPlugin)

// Composition API
// Vue.use(VueCompositionAPI);

Vue.component('downloadExcel', JsonExcel)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

const Backendless = require('backendless')

Vue.use(Backendless)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
