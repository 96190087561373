<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <form-text-input
        v-model="research.researcherName"
        label="Researcher Name"
        name="Researcher Name"
        rules="required"
      />
      <b-form-group
        label="Research Type"
        label-for="researchType"
      >
        <v-select
          id="researchType"
          v-model="research.researchType"
          title="Select an item"
          :aria-errormessage="'required'"
          :aria-invalid="true"
          :options="formOptions.researchTypes"
        />
      </b-form-group>
      <b-form-group
        label="Age Range"
        label-for="ageRange"
      >
        <b-row>
          <b-col cols="6">
            <form-text-input
              v-model="research.minAge"
              label="Minimum Age"
              name="Minimum Age"
              rules="required|between:5,99"
              type="number"
            />
          </b-col>
          <b-col cols="6">
            <form-text-input
              v-model="research.maxAge"
              label="Maximum Age"
              name="Maximum Age"
              rules="required|between:5,99"
              type="number"
            />
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group
        label="Languages"
        label-for="languages"
      >
        <v-select
          id="languages"
          v-model="selectedLangs"
          :reduce="language => language.code"
          title="Select an item"
          :required="true"
          :name="'language'"
          :options="Languages"
          label="name"
          multiple
          @input="$emit('on-select-language', $event)"
        />
      </b-form-group>
      <b-form-group
        label="Description"
        label-for="description"
      >
        <b-form-textarea
          id="description"
          v-model="research.description"
          placeholder="Description"
        />
      </b-form-group>
      <b-form-group>
        <span style="font-size: 12px">Restricted to gender</span>
        <v-select
          id="gender-select"
          v-model="research.gender"
          title="Select an item"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formOptions.gender"
          label="txt"
          :reduce="(item) => item.val"
        />
      </b-form-group>
      <b-form-group>
        <span style="font-size: 12px">Handedness</span>
        <v-select
          id="handedness-select"
          v-model="research.handedness"
          title="Select an item"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formOptions.handedness"
          :reduce="(item) => item.val"
          label="txt"
        />
      </b-form-group>
      <b-form-group
        v-if="research.researchType != null && research.researchType != 'game'"
      >
        <b-form-checkbox v-model="research.vision">
          Vision must be normal or corrected to normal?
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-if="research.researchType != null && research.researchType != 'game'"
      >
        <b-form-checkbox v-model="research.randomize">
          Randomize research
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-if="research.researchType != null && research.researchType != 'game'"
      >
        <b-form-checkbox v-model="research.stats">
          Show Statistics and Confetti
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-if="research.researchType != null && research.researchType != 'game'"
      >
        <b-form-checkbox v-model="research.autoCorrect">
          Auto Correct
        </b-form-checkbox>
      </b-form-group>
      <b-container
        fluid
        class="d-flex flex-row-reverse p-0"
      >
        <b-button
          type="submit"
          variant="primary"
        >
          Next
        </b-button>
      </b-container>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BContainer, BForm, BFormCheckbox, BFormGroup, BFormTextarea, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import FormTextInput from '@/components/FormTextInput.vue'
import { Research } from '@/utils/Research'
import { Languages } from '@/utils/Languages'

export default {
  components: {
    FormTextInput,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormTextarea,
    BContainer,
    BCol,
    BRow,
    BFormCheckbox,
    BButton,
    vSelect,
  },
  emits: ['on-submit', 'on-select-language'],
  props: {
    research: {
      type: Research,
      required: true,
    },
    selectedLanguages: {
      type: Array,
      required: true,
    },
    formOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Languages,
      selectedLangs: [],
    }
  },
  watch: {},
  mounted() {
    this.selectedLangs = this.research?.localizations?.map(locale => locale.lang)
    console.log(this.research.stats)
  },
  methods: {
    onSubmit() {
      if (this.selectedLanguages.length === 0) {
        this.$bvToast.toast('Please select at least one language', {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 5000,
        })
        return
      }
      this.$emit('on-submit')
    },
  },
}
</script>
