<template>
  <b-modal
    ref="bmodal"
    hide-footer
    @show="resetModal"
    @hidden="resetModal"
  >
    <template #modal-title>
      <b-row>
        <b-col cols="10">
          <h4 class="mb-0">
            {{ stepHeaders[step] }}
          </h4>
        </b-col>
      </b-row>
    </template>
    <b-container v-if="step === 0">
      <first-step
        :form-options="formOptions"
        :research="research"
        :selected-languages="selectedLanguages"
        @on-submit="onStepSubmit"
        @on-select-language="(arr) => updateSelectedLanguages(arr)"
      />
    </b-container>
    <b-container
      v-for="(language, index) in selectedLanguages"
      v-if="step === index + 1"
      :key="language.code"
    >
      <b-container>
        <localization-step
          :language="language"
          :localization="research.localizations[index]"
          @on-submit="onStepSubmit"
          @on-prev="step--"
        />
      </b-container>
    </b-container>

    {{ /*  Game step, shown on the step before the last  */ }}
    <b-container
      v-if="step === numSteps - 2 && research.researchType === 'game'"
    >
      <game-step
        :form-options="formOptions"
        :research="research"
        @on-submit="onStepSubmit"
        @on-prev="step--"
      />
    </b-container>
    <b-container v-if="step === numSteps - 1">
      <h4>Ready to upload to Game Changers?</h4>
      <p>Press Submit to upload your research.</p>

      <b-container
        fluid
        class="d-flex flex-row-reverse p-0"
      >
        <b-button
          variant="primary"
          @click="onSubmit"
        >
          Submit
        </b-button>
        <b-button
          class="mr-1"
          variant="secondary"
          @click="step--"
        >
          Prev
        </b-button>
      </b-container>
    </b-container>
  </b-modal>
</template>

<script>
/**
 This component displays a dialogue which guides the researcher through adding or editing a survey on gamechangers.

 In the first step, the researcher inputs the following information:
 - Their name
 - The type of research (game or survey)
 - The minimum and maximum age of the participants
 - The language(s) the survey will be distributed in
 - An internal description of the research

 In the following steps, for each selected language the researcher will be asked to input the following information:
 - The name of the survey
 - The instructions for the survey
 - A description of the rewards for completing the survey

 If the research is a game, another step is added for the game details

 Finally, a review page is displayed, and the researcher can submit the survey.
 */

import {
  BButton, BCol, BContainer, BModal, BRow,
} from 'bootstrap-vue'
import { Research, ResearchLocalization } from '@/utils/Research'
import { Languages, LanguagesDict } from '@/utils/Languages'
import LocalizationStep from '@/views/pages/GameResearches/Modals/LocalizationStep.vue'
import GameStep from '@/views/pages/GameResearches/Modals/GameStep.vue'

import FirstStep from './FirstStep.vue'

export default {
  components: {
    BModal,
    BContainer,
    LocalizationStep,
    BRow,
    BCol,
    BButton,
    GameStep,
    FirstStep,
  },
  props: {
    formOptions: {
      type: Object,
      required: true,
    },
    research: {
      type: Research,
      required: true,
    },
  },
  data() {
    return {
      step: 0,
      Languages,
    }
  },

  emits: ['reset-modal', 'on-submit'],
  expose: ['show', 'hide'],

  computed: {
    stepHeaders() {
      const headers = ['Details']
      headers.push(...this.selectedLanguages?.map(language => language.name))
      if (this.research.researchType === 'game') headers.push('Game Details')
      headers.push('Review')
      return headers
    },
    selectedLanguages() {
      console.log(this.research.localizations.map(localization => LanguagesDict[localization.lang]))
      return this.research.localizations.map(localization => LanguagesDict[localization.lang])
    },

    numSteps() {
      return (
        this.selectedLanguages.length
          + 2
          + (this.research.researchType === 'game' ? 1 : 0)
      )
    },
  },

  watch: {
    'research.researchType': function () {
      this.step = 0
    },
  },
  methods: {
    resetModal() {
      this.step = 0
      this.$emit('reset-modal')
    },
    onStepSubmit() {
      this.step++
    },
    onSubmit() {
      this.$emit('on-submit', this.research)
    },
    show() {
      this.$refs.bmodal?.show()
    },
    hide() {
      this.$refs.bmodal?.hide()
    },
    updateSelectedLanguages(newSelected) {
      this.research.localizations = this.research.localizations.filter(
        localization => newSelected.some(language => language.code === localization.lang),
      )
      newSelected.forEach(language => {
        if (
          !this.research.localizations.some(
            localization => localization.lang === language.code,
          )
        ) {
          this.research.localizations.push(
            new ResearchLocalization({
              lang: language.code,
              title: '',
              instructions: '',
              reward: '',
            }),
          )
        }
      })
    },
  },

}
</script>
