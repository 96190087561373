var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Select Game")]),_c('v-select',{attrs:{"title":"Select an item","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.formOptions.gameTypes,"reduce":(game) => {
            return {
              routName: game.routName,
              objectId: game.objectId,
              level: _vm.research.game.level,
            };
          },"label":"name"},model:{value:(_vm.research.game),callback:function ($$v) {_vm.$set(_vm.research, "game", $$v)},expression:"research.game"}})],1),(
        _vm.research.game != null &&
        _vm.selectedGameOptions != null &&
        _vm.selectedGameOptions.length
      )?_c('b-form-group',[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Selected Game options")]),_c('v-select',{attrs:{"title":"Select an item","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.selectedGameOptions,"reduce":(option) => option.value,"label":"name"},model:{value:(_vm.research.game.level),callback:function ($$v) {_vm.$set(_vm.research.game, "level", $$v)},expression:"research.game.level"}})],1):_vm._e(),_c('b-container',{staticClass:"d-flex flex-row-reverse p-0",attrs:{"fluid":""}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Next ")]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$emit('on-prev')}}},[_vm._v(" Prev ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }