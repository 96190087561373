/** *****************************************************************
 * Twilio.js
 * Generated by Backendless Corp.
 ******************************************************************* */
import Backendless from 'backendless'

const Utils = {
  isObject: obj => obj === Object(obj),
  isString: obj => Object.prototype.toString.call(obj).slice(8, -1) === 'String',
  isNumber: obj => Object.prototype.toString.call(obj).slice(8, -1) === 'Number',
  isBoolean: obj => Object.prototype.toString.call(obj).slice(8, -1) === 'Boolean',
  isDate: obj => Object.prototype.toString.call(obj).slice(8, -1) === 'Date',
}

Backendless.APIServices.Twilio = {
  sendMessage(phoneNumber, message, mediaUrl, options) {
    if (!Utils.isString(phoneNumber)) {
      throw new Error('Invalid value for argument "phoneNumber". Must be string value')
    }

    if (!Utils.isString(message)) {
      throw new Error('Invalid value for argument "message". Must be string value')
    }

    if (!Utils.isString(mediaUrl)) {
      throw new Error('Invalid value for argument "mediaUrl". Must be string value')
    }

    if (!Utils.isObject(options)) {
      throw new Error('Invalid value for argument "options". Must be object value')
    }

    const args = {
      phoneNumber,
      message,
      mediaUrl,
      options,
    }

    return Backendless.APIServices.invoke('Twilio', 'sendMessage', args)
  },

  sendVerificationCode(phoneNumber) {
    if (!Utils.isString(phoneNumber)) {
      throw new Error('Invalid value for argument "phoneNumber". Must be string value')
    }

    const args = phoneNumber
    return Backendless.APIServices.invoke('Twilio', 'sendVerificationCode', args)
  },

  checkVerificationCode(transactionId, code) {
    if (!Utils.isString(transactionId)) {
      throw new Error('Invalid value for argument "transactionId". Must be string value')
    }

    if (!Utils.isString(code)) {
      throw new Error('Invalid value for argument "code". Must be string value')
    }

    const args = {
      transactionId,
      code,
    }

    return Backendless.APIServices.invoke('Twilio', 'checkVerificationCode', args)
  },

  loginWithCode(transactionId, code) {
    if (!Utils.isString(transactionId)) {
      throw new Error('Invalid value for argument "transactionId". Must be string value')
    }

    if (!Utils.isString(code)) {
      throw new Error('Invalid value for argument "code". Must be string value')
    }

    const args = {
      transactionId,
      code,
    }

    return Backendless.APIServices.invoke('Twilio', 'loginWithCode', args)
  },

  loginWithPhoneNumber(phoneNumber) {
    if (!Utils.isString(phoneNumber)) {
      throw new Error('Invalid value for argument "phoneNumber". Must be string value')
    }

    const args = phoneNumber
    return Backendless.APIServices.invoke('Twilio', 'loginWithPhoneNumber', args)
  },

  loginWithPhoneNumberAndPassword(phoneNumber, password) {
    if (!Utils.isString(phoneNumber)) {
      throw new Error('Invalid value for argument "phoneNumber". Must be string value')
    }

    if (!Utils.isString(password)) {
      throw new Error('Invalid value for argument "password". Must be string value')
    }

    const args = {
      phoneNumber,
      password,
    }

    return Backendless.APIServices.invoke('Twilio', 'loginWithPhoneNumberAndPassword', args)
  },

  enable2FA() {
    const args = null

    return Backendless.APIServices.invoke('Twilio', 'enable2FA', args)
  },

  disable2FA() {
    const args = null

    return Backendless.APIServices.invoke('Twilio', 'disable2FA', args)
  },
}
