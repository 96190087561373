<template>
  <validation-provider
    #default="{ errors, valid }"
    :name="name"
    :rules="rules"
    :vid="vid"
  >
    <b-form-group :label="label" label-for="inputField">
      <b-form-input
        v-model="innerValue"
        :state="validationState({ errors, valid })"
        id="inputField"
        :type="type"
      />
      <b-form-invalid-feedback id="inputLiveFeedback">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { BFormInput, BFormInvalidFeedback, BFormGroup } from "bootstrap-vue";
export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    vid: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
  },

  methods: {
    validationState({ errors, valid }) {
      return errors[0] ? false : valid ? true : null;
    },
  },

  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
