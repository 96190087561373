<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group>
        <span style="font-size: 12px">Select Game</span>
        <v-select
          v-model="research.game"
          title="Select an item"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formOptions.gameTypes"
          :reduce="
            (game) => {
              return {
                routName: game.routName,
                objectId: game.objectId,
                level: research.game.level,
              };
            }
          "
          label="name"
        >
        </v-select>
      </b-form-group>

      <b-form-group
        v-if="
          research.game != null &&
          selectedGameOptions != null &&
          selectedGameOptions.length
        "
      >
        <span style="font-size: 12px">Selected Game options</span>
        <v-select
          v-model="research.game.level"
          title="Select an item"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="selectedGameOptions"
          :reduce="(option) => option.value"
          label="name"
        >
        </v-select>
      </b-form-group>
      <b-container fluid class="d-flex flex-row-reverse p-0">
        <b-button type="submit" variant="primary"> Next </b-button>
        <b-button @click="$emit('on-prev')" class="mr-1" variant="secondary">
          Prev
        </b-button>
      </b-container>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import {
  BForm,
  BFormGroup,
  BFormTextarea,
  BCol,
  BRow,
  BContainer,
  BButton,
} from "bootstrap-vue";
import FormTextInput from "@/components/FormTextInput.vue";
import vSelect from "vue-select";
import { Research } from "@/utils/Research";
export default {
  components: {
    FormTextInput,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormTextarea,
    BContainer,
    BCol,
    BRow,
    BButton,
    vSelect,
  },
  emits: ["on-submit", "on-prev"],
  props: {
    research: {
      type: Research,
      required: true,
    },
    formOptions: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectedGameOptions() {
      // get the formOptions.gameTypes[i].options where i is the index of the selected game (has the same objectId as researchData.game)
      // return the options
      if (!this.research.game) return [];
      const game = this.formOptions.gameTypes.find(
        (game) => game.objectId === this.research.game.objectId
      );

      return game?.options ?? [];
    },
  },
  methods: {
    onSubmit() {
      this.$emit("on-submit");
    },
  },
  watch: {},
};
</script>
